<template>
    <v-container fluid>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Create Client Contact</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.first_name"
                        label="First Name*"
                        placeholder="First Name"
                        hint="Minimum of 2 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(2), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.last_name"
                        label="Last Name*"
                        placeholder="Last Name"
                        hint="Minimum of 2 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(2), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.title"
                        label="Title*"
                        placeholder="Title"
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(2), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.email"
                        label="Email"
                        placeholder="Email"
                        validate-on="blur"
                        @blur="convertEmptyToNull('email')"
                        :rules="[
                            rules.email,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.phone"
                        label="Phone"
                        placeholder="Phone"
                        hint="###-###-####"
                        persistent-hint
                        validate-on="blur"
                        @input="dashesAndNumbersOnly($event, 'phone', 12);"
                        @blur="convertEmptyToNull('phone')"
                        :rules="[
                            rules.phone,
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.mobile"
                        @input="dashesAndNumbersOnly($event, 'mobile', 12);"
                        label="Mobile"
                        placeholder="Mobile"
                        hint="###-###-####"
                        persistent-hint
                        validate-on="blur"
                        @blur="convertEmptyToNull('mobile')"
                        :rules="[
                            rules.phone,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <FormCreateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
                :parent="parent" 
            />
            
        </v-form>
    </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import FormCreateFooter from "@/components/interface/FormCreateFooter.vue";
import { useFieldValidation } from '@/assets/modules/composables';

const route = useRoute();
const form = ref();
const current = {
    type: "client_contact"
};
const parent = {
    id: route.params.parent_id,
    type: "client"
};
const entityCore = ref({});
const { convertEmptyToNull, dashesAndNumbersOnly } = useFieldValidation(entityCore);
</script>