<template>
    <v-container fluid>
        <LoadingBar/>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Create Project</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.joc_project_number"
                        label="JOC Project Number*"
                        placeholder="JOC Project Number"
                        validate-on="blur"
                        @input="numbersOnly($event, 'joc_project_number');"
                        @blur="convertToNumber('joc_project_number')" 
                        hint="Numbers only"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.estimate"
                        @input="numbersAndDecimalsOnly($event, 'estimate');"
                        label="Estimate"
                        placeholder="Estimate"
                        @blur="convertToNumber('estimate')"
                        validate-on="blur"
                        :rules="[
                            rules.numberWithDecimal
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.laccd_project_number"
                        label="LACCD Project Number*"
                        placeholder="LACCD Project Number"
                        @blur="convertEmptyToNull('laccd_project_number')"
                        :rules="[
                            rules.required,
                            rules.max(16)
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.financial_id"
                        label="Financial ID*"
                        placeholder="Financial ID"
                        @input="alphanumericAndDashesOnly($event, 'financial_id');"
                        @blur="convertEmptyToNull('financial_id')"
                        :rules="[
                            rules.required,
                            rules.max(16)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.project_type"
                        :items="projectTypesList"
                        item-title="title"
                        item-value="value"
                        label="Project Type"
                        placeholder="Project Type"
                        @blur="convertEmptyToNull('project_type')"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.project_status"
                        :items="projectStatusesList"
                        item-title="title"
                        item-value="value"
                        label="Project Status"
                        placeholder="Project Status"
                        @blur="convertEmptyToNull('project_status')"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.location_uid"
                        :items="locationsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Location*"
                        placeholder="Location"
                        @blur="convertEmptyToNull('location_uid')"
                        @focus="fetchList('location', `owner-${parent.id}`)"
                        :loading="locationsList.loading"
                        :disabled="locationsList.disabled"
                        :hint="locationsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.client_uid"
                        :items="clientsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Client*"
                        placeholder="Client"
                        @blur="convertEmptyToNull('client_uid')"
                        @focus="fetchList('client', `owner-${parent.id}`)"
                        :loading="clientsList.loading"
                        :disabled="clientsList.disabled"
                        :hint="clientsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    <v-select
                        v-model="entityCore.client_contact_uid"
                        :items="clientContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Client Contact*"
                        placeholder="Client Contact"
                        @blur="convertEmptyToNull('client_contact_uid')"
                        @focus="fetchList('client_contact', `client-${entityCore.client_uid}`)"
                        :loading="clientContactsList.loading"
                        :hint="clientContactsList.hint"
                        :disabled="clientContactsList.disabled"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-textarea
                        label="Brief Scope*"
                        v-model.lazy="entityCore.brief_scope"
                        variant="filled"
                        auto-grow
                        @blur="convertEmptyToNull('brief_scope')"
                        :rules="[
                            rules.required,
                            rules.max(2000)
                        ]"
                    ></v-textarea>
                </v-col>
            </v-row>

            <FormCreateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
                :parent="parent" 
            />
        </v-form>
    </v-container>
</template>
<script setup>
import { useStore } from 'vuex';
import { ref, reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import projectTypesList from "@/assets/selects/project-type.json";
import projectStatusesList from "@/assets/selects/project-status.json";
import FormCreateFooter from "@/components/interface/FormCreateFooter.vue";
import LoadingBar from "@/components/interface/LoadingBar.vue";
import { useIsCurrentEntityLoaded, useFetchList, useNameById, useFieldValidation } from "@/assets/modules/composables.js";

const store = useStore();
const entityCore = ref({});
const route = useRoute();
const form = ref();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const { nameById } = useNameById();
const { convertEmptyToNull, alphanumericAndDashesOnly, numbersAndDecimalsOnly, convertToNumber, numbersOnly } = useFieldValidation(entityCore);

const current = {
    type: "project"
};
const parent = {
    id: route.params.parent_id,
    type: "owner"
};



const ownerDoc = computed(() => {
    return store.getters.getEntityDocument(parent.id);
})
const locationsList = computed(() => {
    const items = store.getters.getChildList("owner." + parent.id +".", "location");
    const loading = isLoading[`location_owner-${parent.id}`];
    const hint = ownerDoc.value?._id ? `List of locations for ${ownerDoc.value._name}`:'List of locations';
    const disabled = !isCurrentEntityLoaded.value;
    return {
        items: items,
        hint: hint,
        loading: loading,
        disabled: disabled,
    };
})
const clientsList = computed(() => {
    const items = store.getters.getChildList("owner." + parent.id +".", "client");
    const loading = isLoading[`client_owner-${parent.id}`];
    const hint = ownerDoc.value?._id ? `List of clients for ${ownerDoc.value._name}`:'List of clients';
    const disabled = !isCurrentEntityLoaded.value;
    return {
        items: items,
        hint: hint,
        loading: loading,
        disabled: disabled,
    };
})

const clientContactsList = computed(() => {
    const items = entityCore.value?.client_uid ? store.getters.getChildList("client."+entityCore.value.client_uid+".", "client_contact") : [];
    const hint = entityCore.value?.client_uid ? `List of client contacts for ${nameById(entityCore.value.client_uid)}` : 'Choose a client to see contacts';
    const disabled = !entityCore.value.client_uid;
    const loading = isLoading[`client_contact_client-${entityCore.value.client_uid}`];
    return {
        items: items,
        hint: hint,
        loading: loading,
        disabled: disabled,
    }
})

// Reset the client contact when the client changes
watch(
    () => entityCore.value.client_uid,
    (newVal, oldVal) => {
        if(newVal !== oldVal){
            entityCore.value.client_contact_uid = null;
        }
    }
)
</script>