<template>
    <v-container fluid>
        <LoadingBar/>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Update Unit Price Book</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <FormUpdateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
            />

        </v-form>
    </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import { useIsCurrentEntityLoaded, useEntityCore } from "@/assets/modules/composables.js";
import LoadingBar from "@/components/interface/LoadingBar.vue";
import FormUpdateFooter from "@/components/interface/FormUpdateFooter.vue";

const route = useRoute();
const form = ref();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { entityCore } = useEntityCore();

const current = {
    id: route.params.entity_id,
    type: "unit_price_book"
};
</script>