<template>
    <v-container fluid>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Create Client</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.email"
                        label="Email"
                        placeholder="Email"
                        validate-on="blur"
                        @blur="convertEmptyToNull('email')"
                        :rules="[
                            rules.email,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.phone"
                        label="Phone"
                        placeholder="Phone"
                        hint="###-###-####"
                        persistent-hint
                        validate-on="blur"
                        @input="dashesAndNumbersOnly($event, 'phone', 12);"
                        @blur="convertEmptyToNull('phone')"
                        :rules="[
                            rules.phone,
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.fax"
                        @input="dashesAndNumbersOnly($event, 'fax', 12);"
                        label="Fax"
                        placeholder="Fax"
                        hint="###-###-####"
                        persistent-hint
                        validate-on="blur"
                        @blur="convertEmptyToNull('fax')"
                        :rules="[
                            rules.phone,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.address_line_1"
                        label="Address 1"
                        placeholder="Address 1"
                        validate-on="blur"
                        @blur="convertEmptyToNull('address_line_1')"
                        :rules="[
                            rules.min(3),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.address_line_2"
                        label="Address 2"
                        placeholder="Address 2"
                        validate-on="blur"
                        @blur="convertEmptyToNull('address_line_2')"
                        :rules="[
                            rules.min(2),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.city"
                        label="City"
                        placeholder="City"
                        validate-on="blur"
                        @blur="convertEmptyToNull('city')"
                        :rules="[
                            rules.min(2),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-select
                        v-model="entityCore.state"
                        :items="statesList"
                        item-title="state"
                        item-value="code"
                        label="State"
                        placeholder="State"
                        @blur="convertEmptyToNull('state')"
                    >
                    </v-select>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        v-model="entityCore.zip"
                        label="Zip"
                        placeholder="Zip"
                        validate-on="blur"
                        @input="dashesAndNumbersOnly($event, 'zip', 10);"
                        @blur="convertEmptyToNull('zip')"
                        hint="##### or #####-####"
                        persistent-hint
                        :rules="[
                            rules.zip,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <FormCreateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
                :parent="parent" 
            />
            
        </v-form>
    </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import statesList from "@/assets/selects/state.json";
import FormCreateFooter from "@/components/interface/FormCreateFooter.vue";
import { useFieldValidation } from '@/assets/modules/composables';

const route = useRoute();
const form = ref();
const current = {
    type: "client"
};
const parent = {
    id: route.params.parent_id,
    type: "owner"
};
const entityCore = ref({});
const { convertEmptyToNull, dashesAndNumbersOnly } = useFieldValidation(entityCore);
</script>