<template>
  <div class="key-value">
    <h5>{{ title }}</h5>
    <p v-if="value">{{ value }}</p>
    <p v-else><em style="font-size:12px;">Not available</em></p>
  </div>
</template>

<script>
export default {
  name: "KeyValue",
  props: ["title", "value"],
};
</script>
