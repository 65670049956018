<template>
    <v-container fluid>
        <ReadHeader :current="current" />

        <v-row>
            <v-col>
                <KeyValue
                :title="`Custom Identifier`"
                :value="entityCore.custom_identifier"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Supervisorial District`"
                :value="entityCore.supervisorial_district"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Notes`"
                :value="entityCore.notes"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Address`"
                :value="entityCore.address_line_1"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Address Continued`"
                :value="entityCore.address_line_2"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`City`"
                :value="entityCore.city"
                ></KeyValue>
            </v-col>
            <v-col cols="3">
                <KeyValue
                :title="`State`"
                :value="entityCore.state"
                ></KeyValue>
            </v-col>
            <v-col cols="3">
                <KeyValue
                :title="`Zip`"
                :value="entityCore.zip"
                ></KeyValue>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { useEntityCore } from "@/assets/modules/composables.js";
import ReadHeader from "@/components/interface/ReadHeader.vue";
import KeyValue from "@/components/interface/Key-Value.vue";
const route = useRoute();

// eslint-disable-next-line no-unused-vars
const { entityCore } = useEntityCore();

const current = {
    type: "location",
    id: route.params.entity_id,
};
</script>