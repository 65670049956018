<template>
    <v-dialog v-model="show" width="auto" persistent>
        <v-card width="800" title="Add Subcontractor">
            <v-container>
                <v-form ref="subcontractorForm" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="dialogCore.subcontractor_uid"
                                :items="subcontractorsList.items"
                                item-title="_name"
                                item-value="_id"
                                label="Subcontractor*"
                                placeholder="Subcontractor"
                                @focus="fetchList('subcontractor', 'owner-' + entityAncestry.owner)"
                                :loading="subcontractorsList.loading"
                                :disabled="subcontractorsList.disabled"
                                :hint="subcontractorsList.hint"
                                persistent-hint
                                :rules="[
                                    rules.required,
                                ]"
                            >
                                <template v-slot:item="{ props, item }">
                                    <v-list-item v-bind="props" :disabled="isAlreadyChosen(item.value)"></v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.amount"
                                label="Amount"
                                placeholder="Amount"
                                validate-on="blur"
                                @input="numbersAndDecimalsOnly($event, 'amount');"
                                @blur="convertToNumber('amount')"
                                :rules="[
                                    rules.required,
                                    rules.numberWithDecimal
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex ga-4 justify-end">
                            <v-btn color="primary" variant="outlined" @click="dialogCancel()">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" variant="flat" @click="dialogSubmit()">
                                Add
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref, defineEmits, defineProps, toRefs, reactive, computed } from 'vue';
import { useFieldValidation, useEntityAncestry, useFetchList } from "@/assets/modules/composables.js";
import rules from "@/assets/modules/rules.js";
const props = defineProps(['currentSubcontractors', 'show']);
const { show } = toRefs(props);
const emit = defineEmits(['subcontractorAdded', 'closeDialog']);
const { entityAncestry } = useEntityAncestry();
import { useStore } from 'vuex';

const subcontractorForm = ref();
const dialogCore = ref({});
const { numbersAndDecimalsOnly, convertToNumber } = useFieldValidation(dialogCore);
const store = useStore();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);

function dialogCancel(){
    emit('closeDialog');
    dialogCore.value = {};
}
async function dialogSubmit(){
    const formValidation = await subcontractorForm.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    emit('subcontractorAdded', dialogCore.value);
    emit('closeDialog');
    dialogCore.value = {};
}


const ownerDoc = computed(() => {
    return store.getters.getEntityDocument(entityAncestry.value.owner);
});

const subcontractorsList = computed(() => {
    const items = store.getters.getChildList("owner." + entityAncestry.value.owner + ".", "subcontractor");
    const hint = ownerDoc.value?._name ? `List of subcontractors from ${ownerDoc.value?._name}.` : 'List of subcontractors.'
    const disabled = !ownerDoc.value?._name;
    const loading = isLoading['subcontractor_owner-' + entityAncestry.value.owner];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})

function isAlreadyChosen(item) {
    return props.currentSubcontractors.some(subcontractor => subcontractor.subcontractor_uid === item);
}
</script>