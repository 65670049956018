<template>
    <v-container fluid>
        <LoadingBar/>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Update Location</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.custom_identifier"
                        label="Custom Identifier"
                        placeholder="Custom Identifier"
                        validate-on="blur"
                        @blur="convertEmptyToNull('custom_identifier')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.supervisorial_district"
                        label="Supervisorial District"
                        placeholder="Supervisorial District"
                        validate-on="blur"
                        @blur="convertEmptyToNull('supervisorial_district')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                        label="Notes"
                        v-model.lazy="entityCore.notes"
                        variant="filled"
                        auto-grow
                        @blur="convertEmptyToNull('notes')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.max(2000)
                        ]"
                    ></v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.address_line_1"
                        label="Address 1*"
                        placeholder="Address 1"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required,
                            rules.min(3),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.address_line_2"
                        label="Address 2"
                        placeholder="Address 2"
                        validate-on="blur"
                        @blur="convertEmptyToNull('address_line_2')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.min(2),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.city"
                        label="City*"
                        placeholder="City"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required,
                            rules.min(2),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-select
                        v-model="entityCore.state"
                        :items="statesList"
                        item-title="state"
                        item-value="code"
                        label="State*"
                        placeholder="State"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        v-model="entityCore.zip"
                        label="Zip*"
                        placeholder="Zip"
                        validate-on="blur"
                        @input="dashesAndNumbersOnly($event, 'zip', 10);"
                        :disabled="!isCurrentEntityLoaded"
                        hint="##### or #####-####"
                        persistent-hint
                        :rules="[
                            rules.required,
                            rules.zip,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <FormUpdateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
            />

        </v-form>
    </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import statesList from "@/assets/selects/state.json";
import { useIsCurrentEntityLoaded, useEntityCore, useFieldValidation } from "@/assets/modules/composables.js";
import LoadingBar from "@/components/interface/LoadingBar.vue";
import FormUpdateFooter from "@/components/interface/FormUpdateFooter.vue";

const route = useRoute();
const form = ref();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { entityCore } = useEntityCore();
const { dashesAndNumbersOnly, convertEmptyToNull } = useFieldValidation(entityCore);
const current = {
    id: route.params.entity_id,
    type: "location"
};
</script>