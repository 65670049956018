<template>
  <v-container fluid align="center">
    <v-row>
      <v-col>
        <h1>{{errorInfo.message}}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" elevation="0" @click="gotToDashboard()">{{errorInfo.buttonText}}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "FoureZeroThree",
  data() {
    return {
      userLoggedIn: false,
    };
  },
  methods: {
    gotToDashboard() {
      var props = { path: "/" }
      this.$router.push(props)
    },
  },
  async mounted() {
    this.userLoggedIn = await this.$store.dispatch("isUserLoggedIn");
  },
  computed: {
    errorInfo(){
      let errorInfo = {
        message: "Insufficient permissions to access this page",
        buttonText: "Go back to the dashboard",
      };
      if(!this.userLoggedIn){
        errorInfo = {
          message: "You are not logged in.",
          buttonText: "Log In",
        };
      }
      return errorInfo;
    },
  },
};
</script>
