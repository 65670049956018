<template>
    <v-dialog v-model="show" width="auto" persistent>
        <v-card width="800" title="Add Option">
            <v-container>
                <v-form ref="optionForm" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.year"
                                label="Year"
                                placeholder="Year"
                                validate-on="blur"
                                @input="numbersOnly($event, 'year');"
                                @blur="convertToNumber('year')"
                                :rules="[
                                    rules.required,
                                    rules.numbersOnly,
                                    rules.exactLength(4),
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.amount"
                                label="Amount"
                                placeholder="Amount"
                                validate-on="blur"
                                @input="numbersAndDecimalsOnly($event, 'amount');"
                                @blur="convertToNumber('amount')"
                                :rules="[
                                    rules.required,
                                    rules.numberWithDecimal
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-date-input
                                prepend-icon=""
                                prepend-inner-icon="$calendar"
                                v-model.lazy="dialogCore.start_date"
                                label="Start Date"
                                @blur="convertToDateObject($event, 'start_date')"
                                validate-on="blur"
                                :rules="[
                                    rules.required,
                                    rules.date,
                                ]"
                            ></v-date-input>
                        </v-col>
                        <v-col>
                            <v-date-input
                                prepend-icon=""
                                prepend-inner-icon="$calendar"
                                v-model.lazy="dialogCore.end_date"
                                label="End Date"
                                @blur="convertToDateObject($event, 'end_date')"
                                validate-on="blur"
                                :rules="[
                                    rules.required,
                                    rules.date,
                                ]"
                            ></v-date-input>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex ga-4 justify-end">
                            <v-btn color="primary" variant="outlined" @click="dialogCancel()">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" variant="flat" @click="dialogSubmit()">
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref, defineEmits, defineProps, toRefs } from 'vue';
import { useFieldValidation } from "@/assets/modules/composables.js";
import rules from "@/assets/modules/rules.js";
const props = defineProps(['currentOptions', 'show']);
const { show } = toRefs(props);
const emit = defineEmits(['optionAdded', 'closeDialog']);

const optionForm = ref();
const dialogCore = ref({});
const { numbersAndDecimalsOnly, convertToNumber, numbersOnly, convertToDateObject } = useFieldValidation(dialogCore);

function dialogCancel(){
    emit('closeDialog');
    dialogCore.value = {};
}
async function dialogSubmit(){
    const formValidation = await optionForm.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    emit('optionAdded', dialogCore.value);
    emit('closeDialog');
    dialogCore.value = {};
}
</script>