<template>
    <div id="loadingBar">
        <v-progress-linear v-if="!isCurrentEntityLoaded" color="#305597" indeterminate></v-progress-linear>
    </div>
</template>
<script setup>
import { useIsCurrentEntityLoaded } from "@/assets/modules/composables.js";
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
</script>
<style>
.v-container{
    position: relative;
}
#loadingBar {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
}
</style>