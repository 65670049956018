const rules = {
  required: value => value !== null && value !== undefined && value !== '' || 'This field is required',
  min: (minNum) => (v) => !v || v.length >= minNum || 'This must be at least ' + minNum + ' characters long',
  max: (maxNum) => (v) => !v || (v.length <= maxNum) || 'This must not exceed ' + maxNum + ' characters',
  exactLength: (length) => (v) => {
    const value = v.toString();
    return !v || value.length === length || 'This must be exactly ' + length + ' characters long';
  },
  phone: v => (v === undefined || v === null || v === '' || /^\d{3}-\d{3}-\d{4}$/.test(v)) || 'Please use the following format: ###-###-####',
  email: v => (v === undefined || v === null || v === '' || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Please enter a valid email address',
  zip: v => (v === undefined || v === null || v === '' || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v)) || 'Please use the following format: ##### or #####-####',

  numberWithDecimal: v => (v === undefined || v === null || v === '' || /^\d+(\.\d+)?$/.test(v)) || 'Please enter a valid number with at most one decimal point',
  numbersOnly: v => (v === undefined || v === null || v === '' || /^\d+$/.test(v)) || 'Please enter a valid number',
  date: v => (v === undefined || v === null || v === '' || /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(v)) || 'Please use the following format: MM/DD/YYYY',

  emailMatch: () => (`The email and password you entered don't match`),
  emailRules: v => v === null || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address',
  requiredFileUpload: function(v) {
    if (v.length === 0) {
      return "Must select a file to upload";
    } else {
      return true;
    }
  },
  // This rule checks to make sure the number is less than two and has at most 4 decimal places
  adjustmentFactor: v => {
    const num = parseFloat(v);
    if (isNaN(num)) {
      return 'Please enter a valid number';
    }
    if (num >= 2) {
      return 'Number must be less than 2';
    }
    if (!/^\d+(\.\d{1,4})?$/.test(v)) {
      return 'Number must have at most 4 decimal places';
    }
    return true;
  }
};

module.exports = rules;
