<template>
    <v-row>
        <v-col class="d-flex ga-4 justify-end">
            <v-btn color="primary" variant="outlined" @click="formCancel(cancelRedirectString)" :disabled="submitting">
                Cancel
            </v-btn>
            <v-btn color="primary" variant="flat" @click="createFormSubmit()" :loading="submitting" :disabled="submitting">
                Save
            </v-btn>
        </v-col>
    </v-row>
</template>
<script setup>
import { defineProps, toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useFormCancel } from "@/assets/modules/composables.js";
const props = defineProps(['form', 'entityCore', 'current', 'parent', 'formCancelRedirect']);
const { form, entityCore } = toRefs(props);
const { formCancel } = useFormCancel();
const router = useRouter();
const store = useStore();
const submitting = ref(false);


const cancelRedirectString = props.formCancelRedirect ? `${props.formCancelRedirect}` : `${props.parent.type}/${props.parent.id}`;


async function createFormSubmit(){
    const formValidation = await form.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    submitting.value = true;
    const submission = await store.dispatch("entityGlobalCreateWithoutRoute", {
        payload: entityCore.value,
        routing: {
            entityTypeToCreate: props.current.type,
            // Have the null option for top level entities that don't have a parent
            parentId: props?.parent?.id ? props.parent.id : null,
        },
    });
    if(submission.success){
        if(props.current.type === "proposal" || props.current.type === "supplemental_proposal"){
            console.log("Generating calculations for proposal");
            store.dispatch("generateCalulations", {entityID: submission.newID});
        }
        router.push(`/${props.current.type}/${submission.newID}`);
    }else{
        throw new Error("Form submission failed.");
    }
}
</script>