import store from '../../lib/store'
export default async function validatePermission(value) {
  try {
    let userPermissions = store.getters.getUserPermissions
    if (userPermissions === undefined) {
      const isUserLoggedIn = await store.dispatch("isUserLoggedIn") ? true : false;
      if(!isUserLoggedIn){
        return false;
      }
      await store.dispatch("setupUser");
      userPermissions = await store.getters.getUserPermissions
    }
    if (!userPermissions.includes(value)) {
      return false
    }else{
      return true
    }
  } catch (catchError) {
    throw new Error(catchError);
  }
}

// To use this in the rest of the application, use: this.$validatePermission('stringToValidateHere')