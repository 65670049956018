export default {
  data() {
    return {};
  },
  methods: {
    displayNameFromJSON(value, array){
      let result = "";
      array.forEach((element) => {
        if(element.value === value){
          result = element.title;
        }
      });
      return result;
    },
    goToTemplateEntity(ID) {
      let entityType = this.$store.getters.getEntityByID(ID).type;
      // Navigate to the update view of the clicked entity.
      let props = {
        path: "/" + this.entityType + "/" + this.entityObject._id + "/editor",
      }; // Set the default path to the update view for the parent page entity
      if (entityType && ID) {
        // If we're passed both parameters, create a new path to navigate to for child view entities
        props = { path: "/" + entityType + "/" + ID + "/editor" };
      }
      this.$router.push(props);
    },

    goToUpdateEntity(ID) {
      let entityType = this.$store.getters.getEntityByID(ID).type;
      // Navigate to the update view of the clicked entity.
      let props = {
        path: "/" + this.entityType + "/" + this.entityObject._id + "/update",
      }; // Set the default path to the update view for the parent page entity
      if (entityType && ID) {
        // If we're passed both parameters, create a new path to navigate to for child view entities
        props = { path: "/" + entityType + "/" + ID + "/update" };
      }
      this.$router.push(props);
    },
    // Navigate to the read view of an entity by type and id
    goToReadEntity(ID) {
      let entityType = this.$store.getters.getEntityByID(ID).type;
      var props = { path: "/" + entityType + "/" + ID + "/read" };
      this.$router.push(props);
    },
    // Fire the goToReadEntity method to navigate to the clicked entity read view. This is needed because what is passed by default from this component.
    tableRowClick(item, row) {
      this.goToReadEntity(row.item.key);
    },

    goToCreateEntity(entityType) {
      let props = {
        path:
          "/" +
          this.entityType +
          "/" +
          this.entityObject._id +
          "/" +
          entityType +
          "/create",
      };
      if (!this.entityType) {
        props = { path: "/" + entityType + "/create" };
      }
      this.$router.push(props);
    },
  },
};
