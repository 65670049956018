<template>
    <div>
        <v-breadcrumbs :items="currentItem">
        <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:title="{ item }">
            <div v-html="item.title"></div>
        </template>
        </v-breadcrumbs>
    </div>
</template>

<script setup>
import { entityTitles } from "@/assets/modules/entityTitles.js";
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
const store = useStore();
const route = useRoute();

// Fetch the main entity when the page changes
watch(route, async () => {
    const pageType = route.meta.pageType;
    const entityID = route.params.entity_id;
    const parentID = route.params.parent_id;
    const entityType = route.meta.entityTypeToFetch;
    if(pageType === "read" || pageType === "update"){
        await store.dispatch("entityGlobalRead", {
            entityID: entityID,
            entityType: entityType,
            currentEntity: true,
        });
    }
    if(pageType === "create" && parentID){
        await store.dispatch("entityGlobalRead", {
            entityID: parentID,
            entityType: route.meta.parentType,
            currentEntity: true,
        });
    }
})

const currentItem = computed(() => {
    const pageType = route.meta.pageType;
    const entityID = route.params.entity_id;
    const parentID = route.params.parent_id;
    const entityType = route.meta.entityTypeToFetch;
    let currentEntity = null;
    let breadcrumbItems = [];

    if(pageType === "list"){
        return [
            {
                title: route.name,
            },
        ];
    }
    if(pageType === "read" || pageType === "update"){
        currentEntity = store.getters.getEntityDocument(entityID);
        if(!currentEntity){
            store.dispatch("entityGlobalRead", {
                entityID: entityID,
                entityType: entityType,
                currentEntity: true,
            });
        }
    }
    if(pageType === "create" && parentID){
        currentEntity = store.getters.getEntityDocument(parentID);
        if(!currentEntity){
            store.dispatch("entityGlobalRead", {
                entityID: parentID,
                entityType: route.meta.parentType,
                currentEntity: true,
            });
        }
    }

    if(currentEntity){
        const pathArray = currentEntity._path.split('.');

        pathArray.forEach((itemString, index) => {
            if(index === 0){
                if(itemString === "owner"){
                    breadcrumbItems.push({
                        title: "Owners",
                        href: "/#/owners",
                    });
                }
                if(itemString === "contractor"){
                    breadcrumbItems.push({
                        title: "Contractors",
                        href: "/#/contractors",
                    });
                }
            }
            // If looking at an entity ID string
            if((index-1) % 2 === 0){
                const loopedEntityID = itemString;
                const loopedEntitytype = pathArray[index-1];
                const loopedEntity = store.getters.getEntityDocument(loopedEntityID);
                if(loopedEntity){
                    const entityTitle = entityTitles[loopedEntitytype].singular || '';
                    const loopedEntityDetails = {
                        title: "<span class='entity-type'>" + entityTitle + "</span>" + loopedEntity._name,
                        type: loopedEntity._type,
                        href: `/#/${loopedEntitytype}/${loopedEntityID}`,
                    };
                    breadcrumbItems.push(loopedEntityDetails);
                }
                // If the entity is not loaded, load it and put a placeholder in the breadcrumb
                else{
                    breadcrumbItems.push({
                        title: "...",
                    });
                    store.dispatch("entityGlobalRead", {
                        entityID: loopedEntityID,
                        entityType: loopedEntitytype,
                    });
                }
            }
        });
    }
    if(pageType === "create"){
        breadcrumbItems.push({
            title: route.meta.breadcrumbTitle,
        });
    }
    if(pageType === "update"){
        breadcrumbItems.push({
            title: "Update",
        });

    }
    // Remove the link from the current entity in the breadcrumbs
    if(route.meta.pageType === "read" && breadcrumbItems.length > 0){
        delete breadcrumbItems[breadcrumbItems.length - 1].href;
    }

    return breadcrumbItems;
})
</script>
<style>
.v-breadcrumbs {
font-size: 13px;
}
.v-breadcrumbs-item {
position: relative;
}
.v-breadcrumbs-item:hover span.entity-type,
.v-breadcrumbs-item:focus span.entity-type{
opacity: 1;
top: -1rem;
}
span.entity-type{
font-size: 10px !important;
text-transform: capitalize;
position: absolute;
top: 0;
left: .25rem;
opacity: 0;
transition: all 0.3s ease;
white-space: nowrap;
}
.v-breadcrumbs-item--disabled {
cursor: default;
pointer-events:all;
}
</style>
