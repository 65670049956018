<template>
    <v-dialog v-model="show" width="auto" persistent>
        <v-card width="800" title="Create Joint Scope Meeting Invitation">
            <v-container>
                <v-form ref="jointScopeMeetingInvitationForm" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-date-input
                                prepend-icon=""
                                prepend-inner-icon="$calendar"
                                v-model.lazy="dialogCore.date"
                                label="Date*"
                                @blur="convertToDateObject($event, 'date')"
                                validate-on="blur"
                                :rules="[
                                    rules.required,
                                    rules.date,
                                ]"
                            ></v-date-input>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.time"
                                label="Time*"
                                placeholder="time"
                                @blur="convertEmptyToNull('time')"
                                :rules="[
                                    rules.required,
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex ga-4 justify-end">
                            <v-btn color="primary" variant="outlined" @click="dialogCancel()">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" variant="flat" @click="dialogSubmit()">
                                Add
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script setup>
/* eslint-disable */
import { ref, defineEmits, defineProps, toRefs, } from 'vue';
import { useFieldValidation } from "@/assets/modules/composables.js";
import rules from "@/assets/modules/rules.js";
const props = defineProps(['show']);
const { show } = toRefs(props);
const emit = defineEmits(['closeDialog']);
import { useStore } from 'vuex';

const jointScopeMeetingInvitationForm = ref();
const dialogCore = ref({});
const { convertEmptyToNull, convertToDateObject } = useFieldValidation(dialogCore);
const store = useStore();

function dialogCancel(){
    emit('closeDialog');
    dialogCore.value = {};
}
import { useRoute } from 'vue-router';
const route = useRoute();
async function dialogSubmit(){
    const formValidation = await jointScopeMeetingInvitationForm.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    const meetingDateObj = new Date(dialogCore.value.date);
    const meetingDate = meetingDateObj.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    dialogCore.value.date = meetingDate;
    store.dispatch("pdfGenerator", {
        templateType: "joint-scope-meeting-invitation",
        entityId: route.params.entity_id,
        payload: dialogCore.value,
    });
    emit('closeDialog');
    dialogCore.value = {};
}

</script>