import states from "@/assets/selects/state.json";
import { entityTitles } from "@/assets/modules/entityTitles.js";

export default {
  data() {
    return {
      OWNER: "owner",
      PROJECT: "project",
      CLIENT: "client",
      CONTRACT: "contract",
      SUBCONTRACTOR: "subcontractor",
      OWNERCONTACT: "owner_contact",
      CONTRACTORCONTACT: "contractor_contact",
      CLIENTCONTACT: "client_contact",
      LOCATION: "location",
      UNITPRICEBOOK: "unit_price_book",
      CONTRACTOR: "contractor",
      WORKORDER: "work_order",
      ADJUSTMENTFACTOR: "adjustment_factor",
      PROPOSAL: "proposal",
      SUPPLEMENTAL_WORK_ORDER: "supplemental_work_order",
      WORKITEMS: "work_items",
      OPTION: "option",

      entityID: this.$route.params.entity_id,
      parentID: this.$route.params.parent_id,
      states: states, // Get a list of US states for form dropdowns
    };
  },
  methods: {
    /**
     * Get an entity's display name by its ID
     * @param {string} ID - The ID of the entity to get the name of
     * @returns {string} The name of the entity
     */
    nameByID(ID) {
      return this.$store.getters.getEntityByID(ID)?.name;
    },
    async getSelectedProposal(work_order_id){ 
      console.log(work_order_id)
      return await this.$store.dispatch("getSelectedProposal", work_order_id);
    },
    convertPathToArray(path){
      const result = {};
      if(path){
        const elements = path.split('.');
        for (let i = 0; i < elements.length; i += 2) {
          result[elements[i]] = elements[i + 1];
        }
      }
      return result
    },
    compileDisplayName(list, columns){
      //loop through the records
      list.forEach((element) => {
        //loop through columns to combine
        let name = "";
        columns.forEach((column) => {
          name += element[column] + " ";
        });
        //add new value to list
        element.name = name;
      });
      return list;
    },
    addDisplayNameToEachObject(list){
      list.forEach((element) => {
        let item = this.$store.getters.getEntityByID(element.key);
        if(item?.name){
          let name = item?.name
          element.name = name;
        } else{
          element.name = element.first_name + " " + element.last_name;
        }
  
      });       
      return list;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
    /**
     * Converts a timestamp to a date time to be used in tables
     * @param {string} timestamp  A timestamp to convert to a date time
     * @returns  A string that is a formatted date time
     */
    convertToDateTimeForTableDisplay(timestamp) {
      let day = new Date(timestamp).toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      let time = new Date(timestamp).toLocaleString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });
      return day + "<br>" + time;
    },
    /**
     * Converts a timestamp to a date only
     * @param {string} timestamp  A timestamp to convert to a date
     * @returns  A string that is a formatted date
     */
    convertToDateForDisplay(timestamp) {
      let day = new Date(timestamp).toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return day;
    },
    /**
     * This method generates an array of objects that represent the entities to be displayed in a table. Part of the reason that it is needed is because we need the entityType in the array for tableRowClick to work correctly.
     * @param {object} entityObjects  - The object of entities to be displayed in the table. Each object should ahve a key that is the entity ID.
     * @param {array} columns  - The columns to be displayed in the table. Each column should be a string that represents the key of the object to be displayed.
     * @returns {array} tableListArray - An array of objects that represent the entities to be displayed in the table.
     */
    generateTableListArray(entityObjects, columns = []) {
      let tableListArray = [];
      
      if (entityObjects) {
        //loop properties of the object so that we can create the array
        Object.keys(entityObjects).forEach((key) => {
          let newItem = {
            key: key,
          };
          // Iterate over the columns array
          columns.forEach((column) => {
            // Create a value variable to hold the value of the key
            let value = entityObjects[key];

            // Split the column by the period for things like metadata.created_at
            column.split(".").forEach((part) => {
              if(value){
                value = value[part];
              }
            });
            // Add the column and value to the newItem object
            newItem[column] = value;
          });
          tableListArray.push(newItem);
        });
      }
      return tableListArray;
    },
    generateEntityAncestry(navPath) {
      const ancestryObject = {};
      const ancestryArray = [];

      if (navPath) {
        const elements = navPath.split(".");
        for (let i = 0; i < elements.length; i += 2) {
          //each loop is two-part so tracking i and i+1, i is type and i+1 is id
          let entity_id = elements[i + 1];
          let ent = this.$store.getters.getEntityByID(entity_id)

          ancestryObject[ent.type] = ent;
          ancestryObject[ent.type].id = entity_id;
          ancestryArray.push(ent);
        }
      }
      return {obj: ancestryObject, arr: ancestryArray};
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    },
    async getEntity(entityID) {      
      return await this.$store.dispatch("getElementByID", entityID);
    },

    async getRelatedList(relatedType, parentType, parentID, isRoot) {
      let passedID = parentID;
      
      if(isRoot)
        passedID = null;

      // eslint-disable-next-line no-unused-vars
      let param = {
        parentID: passedID,
        parentType: parentType,
        relatedType: relatedType,
      };
      
      return await this.$store.dispatch("appGetListData", param);
    },
    async getRelatedItem(){
      let id = this.entityID?this.entityID:this.parentID;

      // eslint-disable-next-line no-unused-vars
      let item = {
        entityID: id,
        vueBus: this.$eventBus
      };
      //await this.$store.dispatch("getCalulations", item);
      return await this.$store.dispatch("appGetPageData", item);
    }
  },
  computed: {
    // Get the entity object from the store that includes all of the lookup information
    entity() {
      const entity = this.$store.getters.getEntityByID(this.entityID);
      // console.log("entity",entity)
      return entity;
    },
    // This is the primary object we interact with for this entity
    entityObject() {
      const entityObject = this.entity?.entityObj;
      return entityObject || this.defaultEntityObject;
    },
    entityType() {
      // this.entity will be undefined if the entity is not loaded yet so we get entity type from the form data
      const entityType = this.entity?.type || this.thisEntityType;
      return entityType;
    },
    entityNavPath() {
      const entityNavPath = this.entity?.navPath;
      return entityNavPath;
    },
    entityPath() {
      const entityPath = this.entity?.path;
      return entityPath;
    },    
    entityTitle() {
      return entityTitles[this.entityType] || '';
    },
    entityAncestry() {
      return this.generateEntityAncestry(this.entity.navPath);
    },
    parentEntityID(){
      let parentEntityID = this.$route.params.parent_id;
      if(!parentEntityID){
        parentEntityID = this.entityAncestry.arr[this.entityAncestry.arr.length - 2]?.id;
      }
      return parentEntityID;
    },
  },
  watch: {
    // Watch for changes to the entity object and update the local entity object

    entityObject: {
      immediate: true,
      handler(newValue) {
        if(this.localEntityObject)
          this.localEntityObject = JSON.parse(JSON.stringify(newValue));
      },
    },

  },
};
