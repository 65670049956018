import { computed } from 'vue';

function useCalculatedDateData(entityCore){
    // Calculating the dates for completion fields
    const calculatedDateData = computed(() => {
        const data = {
            entityData: {
                calculated_substantial_completion_date: null,
                calculated_extended_substantial_completion_date: null,
                calculated_final_completion_date: null,
                calculated_extended_final_completion_date: null,
            },
            startToSubstantialHint: 'Choose a "Construction Start Date" to calculate the "Substantial Completion Date".',
            substantialToFinalHint: 'Choose a construction start date to see the output.',
        };
        const extensionAmount = entityCore.value.time_extension || 0;
        if(entityCore.value.construction_start_date){
            if(entityCore.value.start_to_substantial_completion_days){
                const constructionStartDate = new Date(entityCore.value.construction_start_date);
                data.entityData.calculated_substantial_completion_date = new Date(constructionStartDate.setDate(constructionStartDate.getDate() + Number(entityCore.value.start_to_substantial_completion_days)));
                data.startToSubstantialHint = "Substantial Completion Date: <strong>" + convertDate(data.entityData.calculated_substantial_completion_date) + "</strong>";
                if(extensionAmount > 0){
                    data.entityData.calculated_extended_substantial_completion_date = new Date(constructionStartDate.setDate(constructionStartDate.getDate() + Number(extensionAmount)));
                    data.startToSubstantialHint += "<br/>Extended Substantial Completion Date: <strong>" + convertDate(data.entityData.calculated_extended_substantial_completion_date) + "</strong>";
                }
            }
            if(entityCore.value.substantial_to_completion_days){
                const constructionStartDate = new Date(entityCore.value.construction_start_date);
                data.entityData.calculated_final_completion_date = new Date(constructionStartDate.setDate(constructionStartDate.getDate() + Number(entityCore.value.start_to_substantial_completion_days) + Number(entityCore.value.substantial_to_completion_days)));
                data.substantialToFinalHint = "Final Completion Date: <strong>" + convertDate(data.entityData.calculated_final_completion_date) + "</strong>";
                if(extensionAmount > 0){
                    data.entityData.calculated_extended_final_completion_date = new Date(constructionStartDate.setDate(constructionStartDate.getDate() + Number(extensionAmount)));
                    data.substantialToFinalHint += "<br/>Extended Final Completion Date: <strong>" + convertDate(data.entityData.calculated_extended_final_completion_date) + "</strong>";
                }
            }
        }
        return data;
    })





    function convertDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        return formattedDate;
        
    }

    return { calculatedDateData };
}

export default useCalculatedDateData;