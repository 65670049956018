<template>
    <v-container fluid>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Create Unit Price Book</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <FormCreateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
                :parent="parent" 
            />
            
        </v-form>
    </v-container>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import FormCreateFooter from "@/components/interface/FormCreateFooter.vue";
const route = useRoute();
const form = ref();
const current = {
    type: "unit_price_book"
};
const parent = {
    id: route.params.parent_id,
    type: "owner"
};
const entityCore = ref({});
</script>