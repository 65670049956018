<template>
    <v-dialog v-model="show" width="auto" persistent>
        <v-card width="800" title="Add Adjustment Factor">
            <v-container>
                <v-form ref="adjustmentFactorForm" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.value"
                                label="Value"
                                placeholder="Value"
                                validate-on="blur"
                                @input="numbersAndDecimalsOnly($event, 'value');"
                                @blur="convertToNumber('value')"
                                :rules="[
                                    rules.required,
                                    rules.numberWithDecimal
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="dialogCore.type"
                                :items="adjustmentFactorTypeList"
                                item-title="title"
                                item-value="value"
                                label="Type"
                                placeholder="Type"
                                @blur="convertEmptyToNull('type')"
                                :rules="[
                                    rules.required,
                                ]"
                            >
                            <template v-slot:item="{ props, item }">
                                <v-list-item v-bind="props" :disabled="isAlreadyChosen(item.value)"></v-list-item>
                            </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex ga-4 justify-end">
                            <v-btn color="primary" variant="outlined" @click="dialogCancel()">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" variant="flat" @click="dialogSubmit()">
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref, defineEmits, defineProps, toRefs } from 'vue';
import { useFieldValidation } from "@/assets/modules/composables.js";
import rules from "@/assets/modules/rules.js";
import adjustmentFactorTypeList from "@/assets/selects/contract-adjustment-factor-type.json";
const props = defineProps(['currentAdjustmentFactors', 'show']);
const { show } = toRefs(props);
const emit = defineEmits(['adjustmentFactorAdded', 'closeDialog']);

const adjustmentFactorForm = ref();
const dialogCore = ref({});
const { numbersAndDecimalsOnly, convertEmptyToNull, convertToNumber } = useFieldValidation(dialogCore);

function dialogCancel(){
    emit('closeDialog');
    dialogCore.value = {};
}
async function dialogSubmit(){
    const formValidation = await adjustmentFactorForm.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    emit('adjustmentFactorAdded', dialogCore.value);
    emit('closeDialog');
    dialogCore.value = {};
}

function isAlreadyChosen(item) {
    return props.currentAdjustmentFactors.some(factor => factor.type === item);
}
</script>