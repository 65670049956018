<template>
  <authenticator :hide-sign-up="true">
      <template v-slot:header>
        <div style="text-align: center;padding-top: 32px;">
          <img
            :src="require('@/assets/JAMB-logo-blue.svg')"
            height="60px"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <p class="amplify-text" >
            <em>©2024 All Rights Reserved</em>
          </p>
        </div>
      </template>

  <v-app id="inspire" v-if="isAppReadyForUser">
    <v-layout>
      <v-navigation-drawer
        color="primary"
        width="256"
        :style="{ borderWidth: '0' }"
        permanent
      >
        <v-col style="height: 64px">
          <img
            :src="require('@/assets/JAMB-logo-white.svg')"
            height="40px"
          />
        </v-col>


        <!-- <v-list-item title="Owners" @click="nav('/owner')"> </v-list-item>
        
        <v-list-item
        v-can="'contractor.create'"
        title="Contractors"
        @click="nav('/contractor')"
        >
      </v-list-item> -->
      <v-list-item title="Owners" @click="nav('/owners')"> </v-list-item>
      <v-list-item title="Contractors" @click="nav('/contractors')"> </v-list-item>
        <template v-slot:append>
          <v-list density="compact" :lines="false" class="bottom">
            <!-- <v-list-item title="isCurrentEntityLoaded" @click="nav('/testing')">{{ isCurrentEntityLoaded }}</v-list-item> -->
            <!-- <v-list-item title="Testing Insights" @click="nav('/testing')"> </v-list-item> -->
            <!-- <v-list-item title="Clear Console" @click="clearConsole()"> </v-list-item> -->
            <v-list-item title="Log State" @click="logState()"> </v-list-item>
            <!-- <v-list-item title="V0.1.1"></v-list-item> -->
          </v-list>
        </template>
      </v-navigation-drawer>

      <v-app-bar color="primary" elevation="0">
        <!-- <v-overlay
          class="align-center justify-center"
          v-model="itemsBeingLoaded"
          style="z-index: 10000"
        >
          <v-progress-circular
            color="primary"
            size="64"
            indeterminate
          ></v-progress-circular>
        </v-overlay> -->

        <Breadcrumbs></Breadcrumbs>
        
        <v-col align="end">
          <v-btn icon @click="logBug">
            <v-icon>mdi-bug</v-icon>
          </v-btn>
        </v-col>
        <template v-slot:append>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props">
                <v-icon>mdi-account-circle</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="logout">
                <v-list-item-title
                  ><v-icon icon="mdi-logout"></v-icon> Sign
                  out</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>

      <v-main>
        <v-container fluid>

          <RouterView></RouterView>
          <DashboardView v-if="isHome"></DashboardView>

          <v-dialog v-model="confirmAction" max-width="800">
            <v-overlay
              contained
              class="d-flex align-center justify-center"
              v-model="confirmOverlay"
            >
              <v-progress-circular
                :size="100"
                color="red"
                indeterminate
              ></v-progress-circular>
            </v-overlay>

            <v-card>
              <v-card-title>Confirm you want to {{ action }}</v-card-title>
              <v-card-text>
                <div v-if="compoundNotice != ''" class="text-caption">
                  {{ compoundNotice }}
                </div>
                <div style="margin-left: 30px" v-html="confirmText"></div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ConfirmTrue()">Yes</v-btn>
                <v-btn @click="confirmAction = false">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="logNewBug" width="auto">
            <v-card max-width="600" title="Describe the bug that you are experiencing.">
              <template v-slot:actions>
                <v-btn
                  @click="submitBug()"
                  >Log Bug
                </v-btn>
                <v-btn
                  text="Cancel"
                  @click="logNewBug = false"
                ></v-btn>
              </template>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col>
                      <v-textarea
                        label="Bug Description"
                        v-model="bugDescription"
                        variant="filled"
                        auto-grow
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>

          <v-snackbar
            v-model="appNotice.show"
            :color="appNotice.color"
            dismissible
            prominent
            :timeout="appNotice.timeout"
            transition="scale-transition"
            align="right"
          >
            <div v-html="appNotice.message"></div>
            <template v-slot:actions>
              <v-btn
                density="compact"
                variant="text"
                elevation="0"
                icon="mdi-window-close"
                @click="appNotice.show = false"
              ></v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-main>
    </v-layout>
  </v-app>

  <v-container v-if="!isAppReadyForUser" align="center" fluid justify="center">
    <v-progress-circular
      color="primary"
      indeterminate
      :size="48"
      :width="6"
      style="height: 100vh"
    ></v-progress-circular>
  </v-container>
</authenticator >
</template>

<script>
import Breadcrumbs from "@/components/interface/AppBreadcrumbs.vue";
import DashboardView from "@/components/dashboard/OwnerAdmin.vue";
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { Hub } from 'aws-amplify/utils';
export default {
  name: "MainPage",
  data() {
    return {
      isUserInfoLoaded: false,
      isAuthorized: false,
      open: ["Users"],
      drawer: false,
      actions: [],
      action: "",
      confirmText: "",
      compoundNotice: "",
      confirmOverlay: false,
      confirmAction: false,
      allClear: false,
      url: "https://d3f8h5tcxf.execute-api.us-west-1.amazonaws.com/Prod/",
      payload: null,
      endpoint: "",
      method: "GET",
      notice: false,
      noticeText: "",
      noticeColor: "red",
      finish: "",
      logNewBug: false,
      bugDescription: null,
    };
  },
  components: {
    Breadcrumbs,
    DashboardView,
    Authenticator
  },
  computed: {
    isCurrentEntityLoaded(){
      return this.$store.getters.isCurrentEntityLoaded;
    },

    isAppReadyForUser(){ 
      return this.$store.getters.getUserPermissions ? true : false;
    },
    isHome() {
      return this.$route.fullPath === "/";
    },
    appNotice() {
      return this.$store.getters.getAppNotice || "";
    },
    itemsBeingLoaded() {
      let itemsBeingLoaded = this.$store.getters.getItemsBeingLoaded;
      if(itemsBeingLoaded){
        if (itemsBeingLoaded.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === "signedIn") {
        await this.$store.dispatch("setupUser");
        this.$router.push('/owners');
      }
    });
    
    const isUserLoggedInOnLoad = await this.$store.dispatch("isUserLoggedIn") ? true : false;
    if(!this.isAppReadyForUser && isUserLoggedInOnLoad){
      await this.$store.dispatch("setupUser");
    }

    this.$eventBus.on("nav", (path, props) => {
      this.nav(path, props);
    });
    this.$eventBus.on("entity-add", (evt) => {
      this.EventResponse(evt);
    });
    this.$eventBus.on("alert", (evt) => {
      this.ShowNotice(evt, "red");
    });
  },
  methods: {
    logState() {
      console.log(this.$store.state);
    },
    clearConsole() {
      console.clear();
    },
    nav(path, message) {
      if (this.$route.path != path) {
        var props = { path: path, props: message };
        this.$router.push(props);
      }
    },
    async logout() {
      this.$store.dispatch("logUserOut");
    },
    logBug() {
      this.logNewBug = true;
    },
    submitBug(){
      console.log("submitBug")
      if(!this.bugDescription){
        let appNotice = {
          message: `Please describe the bug you are experiencing.`,
          success: false,
        };
        this.$store.dispatch("createAppNotice", appNotice);
        return;
      }
      this.logNewBug = false;
      console.log("this.$route",this.$route)
      console.log("this.$store.state.authInfo",this.$store.state.authInfo)
      const bug = {
        description: this.bugDescription,
        route: {
          path: this.$route.path,
          name: this.$route.name,
          params: this.$route.params,
          meta: this.$route.meta,
          href: this.$route.href,
          fullPath: this.$route.fullPath,
        },
        authInfo: {
          permissions: this.$store.state.authInfo.permissions,
          userSub: this.$store.state.authInfo.idToken.sub,
        },
      };
      this.bugDescription = null;
      console.log("dispatching logBugToDB", bug);
      this.$store.dispatch("logBugToDB", bug);
    },
  },
  watch: {
    $route(to) {
      let payload = {
        view: to.name,
      };
      if(to.params.entity_id){
        payload.entityID = to.params.entity_id
      }
      if(to.fullPath === "/"){
        var props = { path: "/owners"};
        this.$router.push(props);
      }
    },
  }
};
</script>

<style>
main {
  overflow: scroll;
  background-color: #f2f5f7;
}
.v-container > .v-container {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.bottom .v-list-item-title {
  font-size: 12px;
}
* {
  box-sizing: border-box;
  font-family: Verdana, sans-serif;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
#app {
  width: 100%;
  height: 100%;
}
#app [data-amplify-authenticator] {
  align-items: center;
  background-color: #E4EBF6;
  display: flex;
  justify-content: center;
  /* height: 100%; */
  padding:48px;
  min-height: 100vh;
}
#app [data-amplify-authenticator] div#description {
  background-color: #ffffff;
  width: 200px;
  padding: 35px;
}
#app [data-amplify-authenticator] > [data-amplify-container] {
  background-color: #fff;
}
div[data-amplify-router] {
  border: none !important;
  box-shadow: none !important;
}
[data-amplify-container] {
  border-radius: 5px;
  box-shadow: 0px 0px 24px 0 #AEC2E5;
  /* color: #ecf0f1; */
  padding: 35px;
}
#app [data-amplify-authenticator] [data-amplify-container] label {
  /* color: #95a5a6; */
  font-size: 0.8em;
}
#app [data-amplify-authenticator] [data-amplify-container] button:hover {
  background-color: #eeeeee;
}
.amplify-label{
  text-align:left;
}
.amplify-authenticator__subtitle {
  text-align:left;
  color:#999999;
}
.amplify-button--primary,
.amplify-button--link {
  background-color: #305597 !important;
  color: #ffffff !important;
}
.amplify-text {
  font-size: 12px;
}
.amplify-phonenumberfield input {
  border-radius: 4px !important;
}
.v-input__details {
    padding-left: 0 !important;
}
</style>
